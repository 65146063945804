import styled from 'styled-components'

export const LocationMain = styled.div`
  background: #fff;
  width: 30vw;
  height: 100vh;
  padding: 20px;
  margin: auto;
  @media (max-width: 991px) {
    width: 50vw;
  }

  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
  }
`

export const LocationHeader = styled.div`
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  span {
    font-size: 20px;
    line-height: 1;
  }
`

export const LocationHeaderIconMenu = styled.svg`
  margin-right: 12px;
  transform: scale(1.4);
  cursor: pointer;
`

export const LocationContainer = styled.div`
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 20px 30px;
  }
`

export const LocationImage = styled.img`
  object-fit: contain;
  border-radius: 8px;
  margin-top: 15px;
  margin: auto;
`

export const LocationLanguage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`
