import { useQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import BackDrop from '../../components/BackDrop';
import SideDrawer from '../../components/SideDrawer';
import { QUERY } from '../../services';
import './style.css';
import * as S from './users.style';

function Users(props) {
  const [showMenu, setShowMenu] = useState(false);

  const { data, error } = useQuery(QUERY.guests);

  const busIdOfUserCurrent = JSON.parse(localStorage.getItem('USER_BASIC_INFO'))
    ?.bus?.id;

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  return (
    <div style={{ background: '#FEFEFF', minHeight: '100vh' }}>
      <S.HomeHeader>
        <S.HomeHeaderIconMenu
          onClick={() => setShowMenu((showMenu) => !showMenu)}
          width={24}
          height={20}
          viewBox="0 0 24 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 1.2C0 0.537258 0.537258 0 1.2 0H22.8C23.4627 0 24 0.537258 24 1.2C24 1.86274 23.4627 2.4 22.8 2.4H1.2C0.537258 2.4 0 1.86274 0 1.2ZM0 9.59992C0 8.93718 0.537259 8.39992 1.2 8.39992H16.8C17.4627 8.39992 18 8.93718 18 9.59992C18 10.2627 17.4627 10.7999 16.8 10.7999H1.2C0.537259 10.7999 0 10.2627 0 9.59992ZM1.2 16.8C0.537258 16.8 0 17.3373 0 18C0 18.6628 0.537258 19.2 1.2 19.2H22.8C23.4627 19.2 24 18.6628 24 18C24 17.3373 23.4627 16.8 22.8 16.8H1.2Z"
            fill="white"
          />
        </S.HomeHeaderIconMenu>
      </S.HomeHeader>
      <S.HomeContainer>
        <div
          className="tableGameDetail"
          style={{
            margin: '10px 0 30px 0',
            paddingBottom: '30px',
            overflowX: 'hidden',
            overflowY: 'auto',
            height: '500px',
          }}
        >
          <table>
            <thead style={{ textAlign: 'center' }}>
              <tr>
                <th className="tableGameDetailTd">
                  <div className="image-text">
                    <span
                      style={{
                        color: '#7C83FD',
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                    >
                      UID
                    </span>
                  </div>
                </th>
                <th className="tableGameDetailTd">
                  <div className="image-text">
                    <span
                      style={{
                        color: '#7C83FD',
                        fontWeight: 500,
                        fontSize: '14px',
                      }}
                    >
                      Guest Name
                    </span>
                  </div>
                </th>
                <th
                  style={{
                    color: '#7C83FD',
                    fontWeight: 500,
                    fontSize: '14px',
                  }}
                  className="tableGameDetailTd"
                >
                  Check in
                </th>
              </tr>
            </thead>
            <tbody style={{ textAlign: 'center' }}>
              {data &&
                data.guests &&
                data.guests.length > 0 &&
                data.guests
                  .filter((guest) => guest.bus.id === busIdOfUserCurrent)
                  .slice()
                  .sort((a, b) => parseInt(a.loginCode) - parseInt(b.loginCode))
                  .map((guest, index) => {
                    return (
                      <tr>
                        <td className="tableGameDetailTd">
                          <div className="image-text">
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#250652',
                              }}
                            >
                              {guest?.loginCode}
                            </span>
                          </div>
                        </td>
                        <td
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#250652',
                          }}
                          className="tableGameDetailTd"
                        >
                          {guest?.fullName}
                        </td>
                        <td
                          style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#250652',
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0 auto',
                          }}
                          className="tableGameDetailTd"
                        >
                          {guest?.busCheckedIn ? (
                            <img
                              style={{ width: '25px', height: '25px' }}
                              alt="#"
                              src={'/ok.png'}
                            />
                          ) : (
                            <img
                              style={{ width: '25px', height: '25px' }}
                              alt="#"
                              src={'/cancel.png'}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>
            Tổng số người:{' '}
            <span>
              {data &&
                data.guests &&
                data.guests.length > 0 &&
                data.guests.filter(
                  (guest) => guest.bus.id === busIdOfUserCurrent
                ).length}
            </span>
          </p>
          <p>
            Số người đã checkin:{' '}
            <span>
              {data &&
                data.guests &&
                data.guests.length > 0 &&
                data.guests.filter(
                  (guest) =>
                    guest.bus.id === busIdOfUserCurrent &&
                    guest.busCheckedIn === true
                ).length}
            </span>
          </p>
          <p>
            Số người chưa checkin:{' '}
            <span>
              {data &&
                data.guests &&
                data.guests.length > 0 &&
                data.guests.filter(
                  (guest) =>
                    guest.bus.id === busIdOfUserCurrent &&
                    guest.busCheckedIn === false
                ).length}
            </span>
          </p>
        </div>
      </S.HomeContainer>
      <SideDrawer show={showMenu} onHideMenu={setShowMenu} />
      {showMenu && <BackDrop click={() => setShowMenu(false)} />}
    </div>
  );
}

export default React.memo(Users);
