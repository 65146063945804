import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const HomeHeader = styled.div`
  background: url('/logo_appbar_2x.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
`

export const HomeHeaderIconMenu = styled.svg`
  margin: 24px;
  color: #000;
`

export const HomeCountDown = styled.div`
  color: #1AA0FF;
  background: #fefeff;
  box-shadow: 0px 20px 20px rgba(90, 90, 90, 0.1);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  margin: 0 40px;
  h6 {
    color: #1AA0FF;
    font-size: 8px;
    letter-spacing: 5px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  h3 {
    color: #1AA0FF;
    font-size: 10px;
    text-transform: uppercase;
    margin-top: 10px;
  }

  p {
    font-size: 24px;
    letter-spacing: 11px;
    margin-bottom: 8px;
  }

  span {
    font-size: 24px;
    letter-spacing: 11px;
    margin-bottom: 8px;
    font-family: 'Quicksand';
  }
`

export const HomeContainer = styled.div`
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 0 17px;
  }

  ul {
    list-style: none;
    padding: 0;
    font-size: 20px;
    margin-top: 40px;
  }

  li {
    &:before {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      /* background: url('https://www.svgrepo.com/show/169312/check-mark.svg'); */
      margin-right: 10px;
    }
    text-indent: -25px;
    padding-left: 20px;
    margin-left: 5px;
    margin-bottom: 15px;
  }

  .title-info {
    margin-left: 5px;
  }
`

export const HomeTitle = styled.div`
  color: #120d26;
  width: 75%;
  h3 {
    font-size: 20px;
  }

  p {
    font-size: 24px;
    font-weight: 700;
  }

  padding: 0 17px;
`

export const HomeCalendar = styled.div``

export const HomeCalendarItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const HomeCalendarDate = styled.div`
  margin-left: 14px;
  h6 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
    color: #747688;
  }
`

export const HomeMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const HomeMenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  background: #faece1;
  box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.17);
  border-radius: 8px;
  width: calc(50% - 8px);
  height: 90px;
  margin-bottom: 16px;
  cursor: pointer;
`

export const HomeMenuContent = styled.div`
  margin-left: 5px;
  h6 {
    font-weight: 700;
    font-size: 14px;
  }

  p {
    font-size: 12px;
  }
`
