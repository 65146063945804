import styled from 'styled-components'

export const FormControl = styled.div`
  margin-bottom: 0px;
  width: 100%;
  input {
    display: block;
    width: 100%;
    padding: 12px 20px;
    border-radius: 10px;
    background-color: #f2f3f5;
    color: #363a40;
    border: 0;
    outline: none;
    font-size: 14px;
    &:focus {
      border: 2px solid #1AA0FF;
    }
  }
`
