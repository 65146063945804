import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 2px;
  background-color: rgba(66, 176, 213, 0.5);
  border: 0;
  color: #fff;
  padding: 1px 6px;
  transition: 0.2s background ease;
  &:hover {
    background: rgba(66, 176, 213, 1);
  }

  ${({ light }) => {
    if (light) {
      return `
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.09);
      color: #555;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 3%);
      &:hover {
        background: rgba(0,0,0,.02)
      }
      `
    }
  }}
`

export const StyledRegister = styled.div`
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url('/logo_appbar.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: max-content;
  height: 100vh;
  display: flex;
`

export const Container = styled.div`
  background: #f9f3f1;
  box-shadow: 1px 1px 108.8px 19.2px rgb(25, 31, 53);
  padding: 4rem;
  width: 45rem;
  border-radius: 2rem;
  margin: auto;
  @media (max-width: 768px) {
    background: url('/bg_login.png');
    width: 100vw;
    border-radius: 0;
    min-height: 100vh;
    border: 0;
    display: flex;
    flex-direction: column;
  }
`

export const HeadingRegister = styled.div`
  text-align: center;
  font-weight: bold;
  color: transparent;
  font-size: 4.5rem;
`

export const FormWrapper = styled.div`
  /* flex: 0 0 50%;
  max-width: 50%;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 14%);
  border-radius: 0.4rem;
  background-color: #fff;
  padding: 3.5rem 3rem 5rem; */
`

export const Form = styled.form``

export const FormControl = styled.div`
  margin-bottom: 2.5rem;
`

export const FormButton = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  position: relative;
`

export const FormButtonSubmit = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 25px;
  height: 60px;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 15px;
  background: rgba(175, 35, 36, 0.8);
  font-size: 15px;
  font-weight: 600;
  font-family: 'Quicksand';
  box-shadow: 0px 10px 35px rgba(111, 126, 201, 0.25);
  outline: none;
  &:hover {
    background: rgba(175, 35, 36, 1);
  }
`

export const FormButtonSubmitIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1AA0FF;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translate(-50%, -50%);
`

export const FormFooter = styled.div`
  text-align: center;
  span {
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
  font-size: 14px;

  .link {
    color: #4c6ef4;
  }
`
