import { gql } from "@apollo/client";

const MUTATION_LOGIN_GUEST = gql`
  mutation LoginGuest($loginCode: String!) {
    loginAsGuest(loginCode: $loginCode) {
      id
      token
      refreshToken
      roles
      user {
        id
        createdAt
        roles
        ... on Guest {
          zodiacSign
          fullName
          rank {
            rankName
            rankOrder
            id
          }
          eventCheckedIn
          eventCheckedOut
          eventLocation
          department
          miniGameCode
        }
      }
    }
  }
`;

const MUTATION_LOGIN_STAFF = gql`
  mutation LoginAsStaff($id: String!, $password: String!) {
    loginAsStaff(id: $id, password: $password) {
      id
      token
      refreshToken
      roles
    }
  }
`;

const MUTATION_LOGIN_EVENT_ORGANIZER = gql`
  mutation LoginAsEventOrganizer($username: String!, $password: String!) {
    loginAsEventOrganizer(username: $username, password: $password) {
      id
      token
      refreshToken
      roles
      user {
        id
        createdAt
        roles
      }
    }
  }
`;

const MUTATION_CREATE_GUEST = gql`
  mutation CreateGuest($input: GuestInput!) {
    createGuest(input: $input) {
      id
    }
  }
`;

const MUTATION_CHECK_IN_EVENT = gql`
  mutation CheckinEvent($guestId: ID!) {
    checkinEvent(guestId: $guestId) {
      id
      zodiacSign
      fullName
      eventCheckedIn
      eventCheckedOut
    }
  }
`;

const MUTATION_CHECK_OUT_EVENT = gql`
  mutation checkoutEvent($guestId: ID!) {
    checkoutEvent(guestId: $guestId) {
      id
      zodiacSign
      fullName
      eventCheckedIn
      eventCheckedOut
    }
  }
`;

const MUTATION_RECEIVE_PRIZE = gql`
  mutation ReceivePrize($miniGamePrizeId: ID!) {
    receivePrize(miniGamePrizeId: $miniGamePrizeId) {
      id
      prizeName
      winner {
        id
        createdAt
        zodiacSign
        fullName
        rank {
          id
          rankName
          rankOrder
        }
        eventCheckedIn
        eventCheckedOut
      }
      guestRank {
        rankName
      }
      received
    }
  }
`;

const MUTATION_CREATE_MINIGAME_PRIZE = gql`
  mutation CreateMiniGamePrize($input: MiniGamePrizeInput!) {
    createMiniGamePrize(input: $input) {
      id
    }
  }
`;

const MUTATION_DELETE_MINIGAME_PRIZE = gql`
  mutation DeleteMiniGamePrize($miniGamePrizeId: ID!) {
    deleteMiniGamePrize(miniGamePrizeId: $miniGamePrizeId)
  }
`;

const MUTATION_CREATE_STAFF = gql`
  mutation CreateStaff($input: StaffInput!) {
    createStaff(input: $input) {
      id
    }
  }
`;

const MUTATION_CREATE_ANNOUNCEMENT = gql`
  mutation ($input: AnnouncementInput!, $imageFile: Upload!) {
    createAnnouncement(input: $input, imageFile: $imageFile) {
      id
    }
  }
`;

const MUTATION_DELETE_ANNOUNCEMENT = gql`
  mutation ($announcementId: ID!) {
    deleteAnnouncement(announcementId: $announcementId)
  }
`;

const MUTATION_CREATE_GUEST_RANK = gql`
  mutation ($input: GuestRankInput!) {
    createGuestRank(input: $input) {
      id
    }
  }
`;

const MUTATION_CREATE_INDIVIDUAL_PERFORMANCE = gql`
  mutation ($input: IndividualPerformanceInput!, $imageFile: Upload!) {
    createIndividualPerformance(input: $input, imageFile: $imageFile) {
      id
    }
  }
`;

const MUTATION_CREATE_INDIVIDUAL_CONTEST = gql`
  mutation ($input: IndividualContestInput!) {
    createIndividualContest(input: $input) {
      id
    }
  }
`;

const MUTATION_OPEN_VOTING_FOR_INDIVIDUAL_CONTEST = gql`
  mutation ($contestId: ID!) {
    openVotingForIndividualContest(contestId: $contestId) {
      id
    }
  }
`;

const MUTATION_CLOSE_VOTING_FOR_INDIVIDUAL_CONTEST = gql`
  mutation ($contestId: ID!) {
    closeVotingForIndividualContest(contestId: $contestId) {
      id
    }
  }
`;

const MUTATION_VOTE_FOR_INDIVIDUAL_PERFORMANCE = gql`
  mutation ($contestId: ID!, $performanceId: ID!) {
    voteForIndividualPerformance(
      contestId: $contestId
      performanceId: $performanceId
    ) {
      id
    }
  }
`;

const MUTATION_CREATE_TEAM_PERFORMANCE = gql`
  mutation ($input: TeamPerformanceInput!, $imageFile: Upload!) {
    createTeamPerformance(input: $input, imageFile: $imageFile) {
      id
    }
  }
`;

const MUTATION_CREATE_TEAM_CONTEST = gql`
  mutation ($input: TeamContestInput!) {
    createTeamContest(input: $input) {
      id
    }
  }
`;

const MUTATION_OPEN_VOTING_FOR_TEAM_CONTEST = gql`
  mutation ($contestId: ID!) {
    openVotingForTeamContest(contestId: $contestId) {
      id
    }
  }
`;

const MUTATION_CLOSE_VOTING_FOR_TEAM_CONTEST = gql`
  mutation ($contestId: ID!) {
    closeVotingForTeamContest(contestId: $contestId) {
      id
    }
  }
`;

const MUTATION_VOTE_FOR_TEAM_PERFORMANCE = gql`
  mutation ($contestId: ID!, $performanceId: ID!) {
    voteForTeamPerformance(
      contestId: $contestId
      performanceId: $performanceId
    ) {
      id
    }
  }
`;

const MUTATION_CHANGE_GUEST_RANK = gql`
  mutation ($guestId: ID!, $rankId: ID!) {
    changeGuestRank(guestId: $guestId, rankId: $rankId) {
      id
    }
  }
`;

const MUTATION_CHANGE_GUEST_ZODIAC = gql`
  mutation ($guestId: ID!, $zodiacSign: ZodiacSign!) {
    changeZodiac(guestId: $guestId, zodiacSign: $zodiacSign) {
      id
    }
  }
`;

const MUTATION_UPDATE_TEAM_PERFORMANCE_IMAGE = gql`
  mutation ($teamPerformanceId: ID!, $imageFile: Upload!){
  updateTeamPerformanceImage(teamPerformanceId: $teamPerformanceId, imageFile: $imageFile){
    id
  }
}
`;

const MUTATION_UPDATE_INDIVIDUAL_PERFORMANCE_IMAGE = gql`
  mutation ($individualPerformanceId: ID!, $imageFile: Upload!) {
    updateIndividualPerformanceImage(
      individualPerformanceId: $individualPerformanceId
      imageFile: $imageFile
    ) {
      id
    }
  }
`;

export {
  MUTATION_LOGIN_GUEST,
  MUTATION_LOGIN_EVENT_ORGANIZER,
  MUTATION_CREATE_GUEST,
  MUTATION_LOGIN_STAFF,
  MUTATION_CHECK_IN_EVENT,
  MUTATION_CHECK_OUT_EVENT,
  MUTATION_RECEIVE_PRIZE,
  MUTATION_CREATE_MINIGAME_PRIZE,
  MUTATION_DELETE_MINIGAME_PRIZE,
  MUTATION_CREATE_STAFF,
  MUTATION_CREATE_ANNOUNCEMENT,
  MUTATION_DELETE_ANNOUNCEMENT,
  MUTATION_CREATE_GUEST_RANK,
  MUTATION_CREATE_INDIVIDUAL_PERFORMANCE,
  MUTATION_CREATE_INDIVIDUAL_CONTEST,
  MUTATION_OPEN_VOTING_FOR_INDIVIDUAL_CONTEST,
  MUTATION_CLOSE_VOTING_FOR_INDIVIDUAL_CONTEST,
  MUTATION_VOTE_FOR_INDIVIDUAL_PERFORMANCE,
  MUTATION_CREATE_TEAM_PERFORMANCE,
  MUTATION_CREATE_TEAM_CONTEST,
  MUTATION_OPEN_VOTING_FOR_TEAM_CONTEST,
  MUTATION_CLOSE_VOTING_FOR_TEAM_CONTEST,
  MUTATION_VOTE_FOR_TEAM_PERFORMANCE,
  MUTATION_CHANGE_GUEST_RANK,
  MUTATION_CHANGE_GUEST_ZODIAC,
  MUTATION_UPDATE_TEAM_PERFORMANCE_IMAGE,
  MUTATION_UPDATE_INDIVIDUAL_PERFORMANCE_IMAGE,
};
