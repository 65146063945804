import { gql } from "@apollo/client";

const QUERY_ACCOUNTCEMENTS = gql`
  query Announcements {
    announcements {
      id
      createdAt
      title
      body
      imageUrl
    }
  }
`;

const QUERY_ME = gql`
  query Me {
    me {
      id
      createdAt
      roles
      ... on Guest {
        zodiacSign
        fullName
        rank {
          id
          rankName
          rankOrder
        }
        eventCheckedIn
        eventCheckedOut
        eventLocation
        department
        tableName
        miniGameCode
      }
      ... on Staff {
        fullName
      }
      ... on EventOrganizer {
        username
      }
    }
  }
`;

const QUERY_ME_STAFF = gql`
  query MeStaff {
    me {
      id
      createdAt
      roles
      ... on Staff {
        fullName
      }
    }
  }
`;

const QUERY_ME_ADMIN = gql`
  query MeAdmin {
    me {
      id
      createdAt
      roles
    }
  }
`;

const QUERY_GUESTS = gql`
  query Guests {
    guests {
      id
      fullName
      rank {
        rankName
        rankOrder
        id
      }
      tableName
      eventCheckedIn
      eventCheckedOut
      eventLocation
      zodiacSign
      department
      miniGameCode
    }
  }
`;

const QUERY_GUEST_BY_ID = gql`
  query Guest($guestId: ID!) {
    guest(guestId: $guestId) {
      id
      fullName
      rank {
        rankName
        rankOrder
        id
      }
      tableName
      eventCheckedIn
      eventCheckedOut
      eventLocation
      zodiacSign
      department
      miniGameCode
    }
  }
`;

const QUERY_STAFFS = gql`
  query Staffs {
    staffs {
      id
      fullName
      createdAt
      roles
    }
  }
`;

const QUERY_MINIGAME_PRIZES_BY_GUEST = gql`
  query MiniGamePrizesByGuest($guestId: ID!) {
    miniGamePrizesByGuest(guestId: $guestId) {
      id
      prizeName
      winner {
        id
        createdAt
        department
        zodiacSign
        fullName
        tableName
        rank {
          id
          rankName
          rankOrder
        }
        eventCheckedIn
        eventCheckedOut
        miniGameCode
      }
      guestRank {
        rankName
      }
      received
    }
  }
`;

const QUERY_MINIGAME_PRIZES = gql`
  query MiniGamePrizes {
    miniGamePrizes {
      id
      prizeName
      winner {
        id
        zodiacSign
        fullName
        tableName
        department
        miniGameCode
      }
      guestRank {
        rankName
      }
      received
    }
  }
`;

const QUERY_GUEST_RANKS = gql`
  query GuestRanks {
    guestRanks {
      id
      rankName
      rankOrder
    }
  }
`;

const QUERY_INDIVIDUAL_PERFORMANCES = gql`
  query IndividualPerformances {
    individualPerformances {
      id
      performanceName
      performedBy {
        id
        fullName
        zodiacSign
      }
      imageUrl
    }
  }
`;

const QUERY_INDIVIDUAL_CONTESTS = gql`
  query IndividualContests {
    individualContests {
      id
      contestName
      performances {
        id
        performanceName
        performedBy {
          id
          fullName
          zodiacSign
        }
        imageUrl
      }
      openForVote
      votes {
        id
        performance {
          id
          performanceName
        }
      }
    }
  }
`;

const QUERY_INDIVIDUAL_CONTESTS_VOTE_BY_GUEST = gql`
  query IndividualContests($guestId: ID!) {
    individualContests {
      id
      contestName
      performances {
        id
        performanceName
        performedBy {
          id
          fullName
          zodiacSign
        }
        imageUrl
      }
      openForVote
      votes {
        id
        performance {
          id
          performanceName
        }
      }
      voteByGuest(guestId: $guestId) {
        id
        performance {
          id
          performanceName
        }
        voteBy {
          id
          fullName
        }
      }
    }
  }
`;


const QUERY_TEAM_PERFORMANCES = gql`
  query {
    teamPerformances {
      id
      performanceName
      performedBy {
          id
          fullName
          zodiacSign
        }
      imageUrl
      startTime
      endTime
    }
  }
`;

const QUERY_TEAM_CONTESTS = gql`
  query {
    teamContests {
      id
      contestName
      performances {
        id
        performanceName
        performedBy {
          id
          fullName
        }
        imageUrl
        startTime
        endTime
      }
      openForVote
      votes {
        id
        performance {
          id
          performanceName
        }
        voteBy {
          id
          fullName
        }
      }
    }
  }
`;

const QUERY_TEAM_CONTESTS_VOTE_BY_GUEST = gql`
  query ($guestId: ID!) {
    teamContests {
      id
      contestName
      performances {
        id
        performanceName
        performedBy {
          id
          fullName
        }
        imageUrl
        startTime
        endTime
      }
      openForVote
      votes {
        id
        performance {
          id
          performanceName
        }
        voteBy {
          id
          fullName
        }
      }
      voteByGuest(guestId: $guestId) {
        id
        performance {
          id
          performanceName
        }
        voteBy {
          id
          fullName
        }
      }
    }
  }
`;

export {
  QUERY_ME,
  QUERY_ME_STAFF,
  QUERY_ME_ADMIN,
  QUERY_GUESTS,
  QUERY_GUEST_BY_ID,
  QUERY_ACCOUNTCEMENTS,
  QUERY_MINIGAME_PRIZES_BY_GUEST,
  QUERY_MINIGAME_PRIZES,
  QUERY_STAFFS,
  QUERY_GUEST_RANKS,
  QUERY_INDIVIDUAL_PERFORMANCES,
  QUERY_INDIVIDUAL_CONTESTS,
  QUERY_INDIVIDUAL_CONTESTS_VOTE_BY_GUEST,
  QUERY_TEAM_PERFORMANCES,
  QUERY_TEAM_CONTESTS,
  QUERY_TEAM_CONTESTS_VOTE_BY_GUEST
};
