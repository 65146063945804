import decode from 'jwt-decode';
import { AUTH_TOKEN } from '../containers/App/constants';

export function getTokenExpirationDate(token) {
	const decoded = decode(token);
	if (!decoded.exp) {
		return null;
	}
	const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
	date.setUTCSeconds(decoded.exp);
	return date;
}

export function isTokenExpired(token) {
	const date = getTokenExpirationDate(token);
	const offsetSeconds = 0;
	if (date === null) {
		return false;
	}
	return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export const refreshToken = (data = {}, callback) => {
	const token = data[AUTH_TOKEN];
	if (token) {
		localStorage.setItem(AUTH_TOKEN, token);
	} else {
		localStorage.removeItem(AUTH_TOKEN);
	}
	callback(token);
};
