import { USER_BASIC_INFO } from '../containers/App/constants.js';

export const useUserBasicInfo = () => {
	let userBasicInfo = null;

	try {
		userBasicInfo = JSON.parse(localStorage.getItem(USER_BASIC_INFO));
	} catch (e) {
		userBasicInfo = null;
	}

	const setUserBasicInfo = (info) =>
		localStorage.setItem(USER_BASIC_INFO, JSON.stringify(info));

	const removeUserBasicInfo = () => localStorage.removeItem(USER_BASIC_INFO);

	return [userBasicInfo, setUserBasicInfo, removeUserBasicInfo];
};
