import { useApolloClient } from '@apollo/client'
import { useAuthToken } from './useAuthToken'

export const useLogout = () => {
  const [, , removeAuthToken] = useAuthToken()
  const apolloClient = useApolloClient()

  const logout = async () => {
    await apolloClient.clearStore()
    removeAuthToken()
    localStorage.removeItem('REFRESH_TOKEN')
  }

  return logout
}
