import { LoadingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { message } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { withRouter } from 'react-router-dom'
import ErrorMessage from '../../components/ErrorMessage'
import InputPassword from '../../components/InputPassword'
import InputTextLoginBus from '../../components/InputTextLoginBus'
import { useLogout } from '../../hooks/useLogout'
import { useUserBasicInfo } from '../../hooks/useUserBasicInfo'
import { MUTATION } from '../../services/index'
import * as S from './login.style'

function LoginStaff(props) {
  const { refreshToken } = props
  const { history } = props
  const [, setUserBasicInfo, removeUserBasicInfo] = useUserBasicInfo()
  const logout = useLogout()

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      id: '',
      password: ''
    }
  })

  const [
    loginStaff,
    { loading: loginStaffing, data: loginStaffData, error: loginStaffError }
  ] = useMutation(MUTATION.loginStaff, {
    errorPolicy: 'all'
  })

  useEffect(() => {
    removeUserBasicInfo()
    logout()
    if (refreshToken && typeof refreshToken === 'function') {
      refreshToken(null)
    }
  }, [])

  useEffect(() => {
    if (!loginStaffing && loginStaffData) {
      const { token, roles } = loginStaffData.loginAsStaff || {}
      message.success('Đăng nhập thành công')
      localStorage.setItem('AUTH_TOKEN', token)
      localStorage.setItem('ROLES', JSON.stringify(roles))
      history.push('/staff')
    }
  }, [loginStaffing, loginStaffData, loginStaffError])

  useEffect(() => {
    if (loginStaffError) {
      message.error(loginStaffError.message.split(':')[1])
      localStorage.removeItem('AUTH_TOKEN')
      localStorage.removeItem('REFRESH_TOKEN')
    }
  }, [loginStaffError])

  const isLogining = loginStaffing

  const handleLogin = (values) => {
    const { id, password } = values
    loginStaff({ variables: { id, password } })
  }

  return (
    <div>
      <S.StyledRegister>
        <S.Container className="container">
          <img alt="" src="/logo.png" style={{ margin: '50px auto 70px' }} />

          <h6
            style={{
              fontSize: '15px',
              fontWeight: 600,
              marginBottom: '30px',
              color: '#fff'
            }}
          >
            Đăng nhập PG
          </h6>

          <S.FormWrapper>
            <S.Form onSubmit={handleSubmit(handleLogin)} noValidate>
              <S.FormControl>
                <Controller
                  name="id"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Tài khoản không được để trống'
                    }
                  }}
                  render={({ field }) => (
                    <InputTextLoginBus
                      name="id"
                      placeholder="ID"
                      onChange={field.onChange}
                      value={getValues('id')}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="id"
                  style={{ paddingLeft: '0rem' }}
                />
              </S.FormControl>
              <S.FormControl>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Mật khẩu không được để trống'
                    }
                  }}
                  render={({ field }) => (
                    <InputPassword
                      name="password"
                      placeholder="Mật khẩu"
                      onChange={field.onChange}
                      value={getValues('password')}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  style={{ paddingLeft: '0rem' }}
                />
              </S.FormControl>
              <S.FormButton>
                <S.FormButtonSubmit type="submit">
                  {isLogining ? <LoadingOutlined /> : 'ĐĂNG NHẬP'}
                </S.FormButtonSubmit>
              </S.FormButton>
            </S.Form>
          </S.FormWrapper>
        </S.Container>
      </S.StyledRegister>
    </div>
  )
}

LoginStaff.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  refreshToken: PropTypes.func.isRequired
}

export default React.memo(withRouter(LoginStaff))
