import * as styled from "styled-components";
import 'antd/dist/antd.css';

const GlobalStyles = styled.createGlobalStyle`
  @font-face {
    font-family: "Quicksand";
    font-weight: 400;
    src: local("Quicksand"),
      url("./Quicksand-Light.otf") format("opentype");
  }

  @font-face {
    font-family: "Quicksand";
    font-weight: 500;
    src: local("Quicksand"),
      url("./Quicksand-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "Quicksand";
    font-weight: bold;
    src: local("Quicksand Bold"),
      url("./Quicksand-Bold.otf") format("opentype");
  }

  body {
    margin: 0;
    font-family: "Quicksand", sans-serif;
    font-size: 15px;
    background-color: #cccccc !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  body .ant-menu-item span a {
    text-decoration: none;
  }

  .white-text {
    color: #fff;
  }

  .sub-light-text {
    color: rgba(255, 255, 255, 0.6);
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .full-height {
    height: 100%;
  }

  body .sHqpo {
    overflow-y: hidden;
  }

  .avatar-small {
    width: 30px;
    height: 30px;
  }
  body .gMLNBR {
    overflow: hidden;
  }

  body .ant-table-row-level-0 {
    cursor: pointer;
  }

  body .ant-table-tbody > tr.ant-table-row {
    border-radius: 1rem !important;
    margin: 10px 0 !important;
    cursor: pointer;
  }

  body .ant-table-pagination {
    font-family: "Quicksand", sans-serif;
  }

  body .ant-pagination-options {
    display: none;
  }

  body .ant-layout-sider-zero-width-trigger {
    display: none;
  }

  body .dUuELG::-webkit-scrollbar {
    height: 10px;
  }

  body .dUuELG::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  body .dUuELG::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999;
  }

  body .ant-message-custom-content {
    display: flex;
    align-items: center;
    width: auto;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  body .anticon-check-circle {
    position: relative;
    margin-right: 15px !important;
  }

  body .anticon-check-circle:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
    border-radius: 4px;
    background-color: rgb(21, 221, 73);
    opacity: 0.2;
  }

  body .anticon-close-circle {
    position: relative;
  }

  body .anticon-close-circle:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
    border-radius: 4px;
    background-color: rgb(248, 96, 134);
    opacity: 0.2;
  }

  body .anticon-info-circle {
    position: relative;
  }

  body .anticon-info-circle:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 200%;
    transform: translate(-25%, -25%);
    border-radius: 4px;
    background-color: #00aefd;
    opacity: 0.2;
  }

  body .ant-message-notice-content {
    padding: 2rem 2rem;
    border-radius: 5px;
    margin-left: 15px !important;
    font-family: "MaerskHeadline", sans-serif;
  }

  body .ant-message-notice-content span {
    margin-left: 7px !important;
  }

  body .ant-table-tbody > tr.ant-table-row:hover > td {
    background: rgba(204, 204, 204, 0.3);
  }

  body .dUuELG {
    background-color: #eff2f7;
  }

  body .ant-modal-content {
    border-radius: 10px !important;
  }

  body .ant-modal-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom: 2px solid #eee;
    padding: 20px !important;
  }

  body .ant-modal-title {
    border-radius: 10px !important;
    color: #250652;
    font-weight: 600;
    font-size: 18px;
  }

  body .ant-modal-close-x {
    font-weight: bold !important;
    font-size: 20px;
    color: rgb(228, 64, 95);
  }

  body .ant-menu-title-content {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
  }

  body .ant-layout-sider-children {
    background-color: #fff;
    padding: 20px;
  }

  body .ant-menu-light {
    border: none;
  }

  body .ant-menu-item {
    border-radius: 10px;
    border: none;
    color: #646a6d !important;
    margin: 15px 0 !important;
    height: 50px !important;
    font-weight: 500;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 25px;
  }

  body .ant-menu-item-icon svg {
    font-size: 20px;
  }

  body .ant-menu-item-active {
    background: linear-gradient(90.06deg, #155aff -4.45%, #53d0fe 104.12%);
    border-radius: 10px;
    border: none;
    color: #fff !important;
    margin: 15px 0 !important;
    height: 50px !important;
  }

  body .ant-menu-item-active .ant-menu-title-content a {
    color: #fff !important;
  }

  body .ant-menu-item-active svg {
    opacity: 1 !important;
  }

  body .ant-menu-item-active path {
    fill: #fff;
  }

  body .ant-menu-item-selected {
    background: linear-gradient(90.06deg, #155aff -4.45%, #53d0fe 104.12%);
    border-radius: 10px;
    border: none;
    color: #fff !important;
    margin: 15px 0 !important;
    height: 50px !important;
  }

  body .ant-menu-item-selected .ant-menu-title-content a {
    color: #fff !important;
  }

  body .ant-menu-item-selected svg {
    opacity: 1 !important;
  }

  body .ant-menu-item-selected path {
    fill: #fff;
  }

  .eMUhTt {
    overflow-y: unset !important;
  }

  body .ant-picker {
    padding: 10px 20px;
    border-radius: 5px;
    color: #250652 !important;
    border: 2px solid #ddd;
    box-shadow: 0 0 0 2px rgb(221 221 221 / 20%);
  }

  body .ant-picker-focused {
    border-width: 2px !important;
  }

  body .ant-picker:hover {
    border-width: 2px !important;
  }

  body .ant-picker-cell .ant-picker-cell-inner {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
  }

  body .ant-picker-cell-selected .ant-picker-cell-inner {
    background: rgba(0, 174, 253, 1) !important;
  }

  body .ant-tabs-tab {
    color: #5e6278;
    font-weight: 500;
    border-radius: 4px !important;
    background: transparent !important;
  }

  body .ant-tabs-tab-active {
    color: #fff !important;
    background: linear-gradient(
      90.06deg,
      rgba(0, 174, 253, 0.5),
      rgba(0, 174, 253, 0.8)
    ) !important;
    padding: 0 2.5rem;
  }

  body .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
    font-weight: 500;
  }

  body .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: rgb(25, 28, 57);
  }

  body .ant-form-item-label {
    width: 104px;
    padding-right: 21px;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
  }

  body .ant-form-item-label label {
    color: rgba(85, 85, 85, 0.8) !important;
  }

  body .ant-select .ant-select-selector {
    min-height: 45px;
    border-radius: 10px;
  }

  body #investorsSelected_0_share {
    padding: 10px;
  }

  body #investorsSelected_1_share {
    padding: 10px;
  }

  body #investorsSelected_2_share {
    padding: 10px;
  }

  body #investorsSelected_3_share {
    padding: 10px;
  }

  body #investorsSelected_4_share {
    padding: 10px;
  }

  body #investorsSelected_5_share {
    padding: 10px;
  }

  body #investorsSelected_6_share {
    padding: 10px;
  }

  body #investorsSelected_7_share {
    padding: 10px;
  }

  body #investorsSelected_8_share {
    padding: 10px;
  }

  body #investorsSelected_9_share {
    padding: 10px;
  }

  body #investorsSelected_10_share {
    padding: 10px;
  }

  body .ant-select-dropdown {
    border-radius: 7px !important;
  }

  body .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-start;
  }

  body .ant-btn-dashed {
    width: 100% !important;
    height: auto;
    padding: 1rem;
    color: #00aefd !important;
    border-color: #00aefd !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  body .ant-btn-dashed:hover {
    color: #00aefd !important;
    border-color: #00aefd !important;
  }

  body .ant-select-selection-item {
    display: flex !important;
    align-items: center;
  }

  body .dPtqMV {
    overflow-y: auto !important;
  }

  body .ant-tabs {
    overflow: none !important;
  }

  body .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }

  body .ant-tabs-nav-list {
    display: flex;
    justify-content: center;
    width: 80%;
    // background: #eee;
    border-radius: 5px;
  }

  body .ant-tabs-top > .ant-tabs-nav::before {
    border: 0;
  }

  body .ant-tabs-tab {
    width: 120px;
    padding: 0 15px;
    height: 60px !important;
    justify-content: center;
  }

  body .ant-table-body {
    overflow-y: auto;
  }

  body .caBklB {
    overflow-y: unset !important;
  }

  .btn-global {
    margin: 10px 0 20px 0;
    padding: 0 2rem;
    border-radius: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body .ant-tag {
    font-size: 11px;
    padding: 7px 14px;
  }

  body .ant-tabs-nav-operations {
    display: none !important;
  }

  body .ant-picker-time-panel-cell-inner {
    font-size: 14px;
    letter-spacing: -1px;
  }

  body .ant-picker-now-btn {
    font-size: 14px;
    letter-spacing: -1px;
  }

  .css-1s2u09g-control {
    border: 0 !important;
  }

  .css-1s2u09g-control:hover {
    border: 0 !important;
  }

  .css-1s2u09g-control:focus {
    border: 0 !important;
  }

  body .ant-select-selection-search {
    display: flex;
    align-items: center;
  }

  body .ant-select-selection-search-input {
    display: flex;
    align-items: center;
  }

  body .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }

  body .ant-picker-ok {
    display: none;
  }

  body .ant-picker-now {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  body .ant-select-selector {
    border-radius: 5px !important;
  }

  .sider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
  }

  .avatar-sider {
    width: 80%;
    height: 100%;
    object-fit: cover;
  }

  .title-sider {
    font-size: 20px;
    font-family: "Quicksand" !important;
    text-align: center;
  }

  .DraftEditor-root {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
  }

  body .ant-table-container {
    /* font-family: "Quicksand", sans-serif; */
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;

  }

  body .ant-btn {
    /* font-family: "Quicksand", sans-serif; */
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  body .ant-form-item {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  body .ant-select {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  body .ant-input {
    padding: 8px 10px;
    border-radius: 5px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  .text-overflow-two-col {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .DraftEditor-editorContainer {
    word-break: break-word;
    height: 200px;
    font-size: 12px;
  }

  .ant-pagination-item-link {
    border-radius: 5px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-item {
    border-radius: 5px !important;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  .ant-select-item {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
  }

  .ant-input {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    margin: 0 10px;
  }

  .ant-input:first-child {
    margin-left: 0;
  }

  .ant-modal-confirm-content {
    font-family: "Quicksand", sans-serif;
  }

  .ant-modal-title {
    font-family: "Quicksand", sans-serif;
  }

  .ant-dropdown-menu-title-content {
    font-family: "Quicksand", sans-serif;
  }

  body .ant-layout {
    font-family: "Quicksand", sans-serif !important;
    /* font-family: 'Quicksand', sans-serif; */
  }

  .ButtonGroup__root___3lEAn:nth-child(5) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    body .ant-tabs-nav {
      display: none !important;
    }

    .img-slp {
      width: 20px;
      margin-right: 5px;
    }

    body .ant-tabs-tabpane {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
    padding: 6px 8px;
    background: #ffffff;
    border: 1px solid #d3d8de;
    border-radius: 4px;
    outline: none;
  }

  .modal-image {
    object-fit: cover;
    border-radius: 8px;
  }

  /* canvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    cursor: pointer;
    z-index: 1;
    pointer-events: none;
  } */

  .spinner-refetch {
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default GlobalStyles;
