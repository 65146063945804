import {
  MUTATION_LOGIN_GUEST,
  MUTATION_LOGIN_EVENT_ORGANIZER,
  MUTATION_CREATE_GUEST,
  MUTATION_LOGIN_STAFF,
  MUTATION_CHECK_IN_EVENT,
  MUTATION_CHECK_OUT_EVENT,
  MUTATION_RECEIVE_PRIZE,
  MUTATION_CREATE_MINIGAME_PRIZE,
  MUTATION_DELETE_MINIGAME_PRIZE,
  MUTATION_CREATE_STAFF,
  MUTATION_CREATE_ANNOUNCEMENT,
  MUTATION_DELETE_ANNOUNCEMENT,
  MUTATION_CREATE_GUEST_RANK,
  MUTATION_CREATE_INDIVIDUAL_PERFORMANCE,
  MUTATION_CREATE_INDIVIDUAL_CONTEST,
  MUTATION_OPEN_VOTING_FOR_INDIVIDUAL_CONTEST,
  MUTATION_CLOSE_VOTING_FOR_INDIVIDUAL_CONTEST,
  MUTATION_VOTE_FOR_INDIVIDUAL_PERFORMANCE,
  MUTATION_CREATE_TEAM_PERFORMANCE,
  MUTATION_CREATE_TEAM_CONTEST,
  MUTATION_OPEN_VOTING_FOR_TEAM_CONTEST,
  MUTATION_CLOSE_VOTING_FOR_TEAM_CONTEST,
  MUTATION_VOTE_FOR_TEAM_PERFORMANCE,
  MUTATION_CHANGE_GUEST_RANK,
  MUTATION_CHANGE_GUEST_ZODIAC,
  MUTATION_UPDATE_TEAM_PERFORMANCE_IMAGE,
  MUTATION_UPDATE_INDIVIDUAL_PERFORMANCE_IMAGE
} from './mutation';

import {
  QUERY_ME,
  QUERY_ME_STAFF,
  QUERY_ME_ADMIN,
  QUERY_GUESTS,
  QUERY_ACCOUNTCEMENTS,
  QUERY_MINIGAME_PRIZES_BY_GUEST,
  QUERY_MINIGAME_PRIZES,
  QUERY_STAFFS,
  QUERY_GUEST_RANKS,
  QUERY_INDIVIDUAL_PERFORMANCES,
  QUERY_INDIVIDUAL_CONTESTS,
  QUERY_INDIVIDUAL_CONTESTS_VOTE_BY_GUEST,
  QUERY_TEAM_PERFORMANCES,
  QUERY_TEAM_CONTESTS,
  QUERY_TEAM_CONTESTS_VOTE_BY_GUEST,
  QUERY_GUEST_BY_ID
} from './query';

export const MUTATION = {
  loginGuest: MUTATION_LOGIN_GUEST,
  checkinEvent: MUTATION_CHECK_IN_EVENT,
  checkoutEvent: MUTATION_CHECK_OUT_EVENT,
  loginStaff: MUTATION_LOGIN_STAFF,
  receivePrize: MUTATION_RECEIVE_PRIZE,
  loginEventOrganzier: MUTATION_LOGIN_EVENT_ORGANIZER,
  createMinigamePrize: MUTATION_CREATE_MINIGAME_PRIZE,
  deleteMinigamePrize: MUTATION_DELETE_MINIGAME_PRIZE,
  createStaff: MUTATION_CREATE_STAFF,
  createGuest: MUTATION_CREATE_GUEST,
  createAnnouncement: MUTATION_CREATE_ANNOUNCEMENT,
  deleteAnnouncement: MUTATION_DELETE_ANNOUNCEMENT,
  createGuestRank: MUTATION_CREATE_GUEST_RANK,
  createIndividualPerformance: MUTATION_CREATE_INDIVIDUAL_PERFORMANCE,
  createIndividualContest: MUTATION_CREATE_INDIVIDUAL_CONTEST,
  openVotingForIndividualContest: MUTATION_OPEN_VOTING_FOR_INDIVIDUAL_CONTEST,
  closeVotingForIndividualContest: MUTATION_CLOSE_VOTING_FOR_INDIVIDUAL_CONTEST,
  voteForIndividualPerformance: MUTATION_VOTE_FOR_INDIVIDUAL_PERFORMANCE,

  createTeamPerformance: MUTATION_CREATE_TEAM_PERFORMANCE,
  createTeamContest: MUTATION_CREATE_TEAM_CONTEST,
  openVotingForTeamContest: MUTATION_OPEN_VOTING_FOR_TEAM_CONTEST,
  closeVotingForTeamContest: MUTATION_CLOSE_VOTING_FOR_TEAM_CONTEST,
  voteForTeamPerformance: MUTATION_VOTE_FOR_TEAM_PERFORMANCE,

  changeGuestRank: MUTATION_CHANGE_GUEST_RANK,
  changeGuestZodic : MUTATION_CHANGE_GUEST_ZODIAC,
  updateTeamPerformanceImage: MUTATION_UPDATE_TEAM_PERFORMANCE_IMAGE,
  updateIndividualPerformanceImage: MUTATION_UPDATE_INDIVIDUAL_PERFORMANCE_IMAGE,
};

export const QUERY = {
  me: QUERY_ME,
  meAdmin: QUERY_ME_ADMIN,
  meStaff: QUERY_ME_STAFF,  
  guests: QUERY_GUESTS,
  guest: QUERY_GUEST_BY_ID,
  announcements: QUERY_ACCOUNTCEMENTS,
  miniGamePrizesByGuest: QUERY_MINIGAME_PRIZES_BY_GUEST,
  minigamePrizes: QUERY_MINIGAME_PRIZES,
  staffs: QUERY_STAFFS,
  guestRanks: QUERY_GUEST_RANKS,
  individualPerformances: QUERY_INDIVIDUAL_PERFORMANCES,
  individualContests: QUERY_INDIVIDUAL_CONTESTS,
  individualContestsVoteByGuest: QUERY_INDIVIDUAL_CONTESTS_VOTE_BY_GUEST,
  teamPerformances: QUERY_TEAM_PERFORMANCES,
  teamContests: QUERY_TEAM_CONTESTS,
  teamContestsVoteByGuest: QUERY_TEAM_CONTESTS_VOTE_BY_GUEST
};
