import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './containers/App'
import {
  AUTH_TOKEN, SERVER_GRAPHQL, ROLE_ADMIN,
  ROLE_STAFF,

} from './containers/App/constants'
import jwtDecode from 'jwt-decode'
import { createBrowserHistory } from 'history'


const httpLink = createUploadLink({
  uri: SERVER_GRAPHQL,
  options: {
    reconnect: true
  }
})

const middlewareLink = new ApolloLink(async (operation, forward) => {
  let tokenValue = localStorage.getItem(AUTH_TOKEN)

  let isExpiredAccessToken = false
  let authRole = []
  try {
    authRole = await jwtDecode(localStorage.getItem(AUTH_TOKEN)).auth

    const expiredAccessToken = await jwtDecode(localStorage.getItem(AUTH_TOKEN))
      .exp
    isExpiredAccessToken = new Date().getTime() / 1000 + 30 > expiredAccessToken


    if (tokenValue) {
      if (isExpiredAccessToken) {
        localStorage.removeItem(AUTH_TOKEN)
        localStorage.removeItem('REFRESH_TOKEN')
        localStorage.removeItem('ROLES')
        localStorage.removeItem('LOGIN_CODE')
  
        const isAdmin = JSON.stringify(authRole) === JSON.stringify(ROLE_ADMIN)
        const isStaff = JSON.stringify(authRole) === JSON.stringify(ROLE_STAFF)
        if (isAdmin) {
          createBrowserHistory().push('/login-admin')
        } else if (isStaff) {
          createBrowserHistory().push('/login-staff')
        } else {
          createBrowserHistory().push('/login')
        }
        window.location.reload()
      }
    }
  
  } catch (error) {}



  operation.setContext({
    headers: {
      Authorization: tokenValue ? `Bearer ${tokenValue}` : ''
    }
  })

  return forward(operation)
})

const httpLinkAuth = middlewareLink.concat(httpLink)

const errorLink = new onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, code }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
      )
    })

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([httpLinkAuth, errorLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
