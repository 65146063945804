export const USER_BASIC_INFO = 'USER_BASIC_INFO'
export const AUTH_TOKEN = 'AUTH_TOKEN'

export const USER_ROLES = {
  ROLE_CREATE_GUEST: 'ROLE_CREATE_GUEST',
  ROLE_VIEW_SELF: 'ROLE_VIEW_SELF',
  ROLE_VIEW_GUEST: 'ROLE_VIEW_GUEST',
  ROLE_CREATE_KYC_REQUEST: 'ROLE_CREATE_KYC_REQUEST',
  ROLE_APPROVE_OR_REJECT_KYC_REQUEST: 'ROLE_APPROVE_OR_REJECT_KYC_REQUEST',
  ROLE_VIEW_KYC_REQUEST: 'ROLE_VIEW_KYC_REQUEST',
  ROLE_CREATE_OR_UPDATE_GUEST_RANK: 'ROLE_CREATE_OR_UPDATE_GUEST_RANK',
  ROLE_VIEW_GUEST_RANK: 'ROLE_VIEW_GUEST_RANK',
  ROLE_CREATE_OR_UPDATE_MINI_GAME_PRIZE:
    'ROLE_CREATE_OR_UPDATE_MINI_GAME_PRIZE',
  ROLE_RESTOCK_MINI_GAME_PRIZE: 'ROLE_RESTOCK_MINI_GAME_PRIZE',
  ROLE_VIEW_MINI_GAME_PRIZES: 'ROLE_VIEW_MINI_GAME_PRIZES',
  ROLE_PLAY_MINI_GAME: 'ROLE_PLAY_MINI_GAME'
}

export const ROLE_ADMIN = [
  'ROLE_CREATE_GUEST',
  'ROLE_CREATE_OR_UPDATE_ANNOUNCEMENT',
  'ROLE_CREATE_OR_UPDATE_CONTEST',
  'ROLE_CREATE_OR_UPDATE_GUEST_RANK',
  'ROLE_CREATE_OR_UPDATE_MINI_GAME_PRIZE',
  'ROLE_CREATE_OR_UPDATE_PERFORMANCE',
  'ROLE_CREATE_STAFF',
  'ROLE_PLAY_MINI_GAME',
  'ROLE_RESTOCK_MINI_GAME_PRIZE',
  'ROLE_VIEW_ANNOUNCEMENT',
  'ROLE_VIEW_CONTEST',
  'ROLE_VIEW_GUEST',
  'ROLE_VIEW_GUEST_RANK',
  'ROLE_VIEW_MINI_GAME_PRIZE',
  'ROLE_VIEW_PERFORMANCE',
  'ROLE_VIEW_SELF',
  'ROLE_VIEW_STAFF',
  'ROLE_CHECK_IN_OR_CHECK_OUT_EVENT'
]

export const ROLE_GUEST = ['ROLE_VIEW_SELF', 'ROLE_VIEW_ANNOUNCEMENT']

export const ROLE_STAFF = [
  'ROLE_VIEW_SELF',
  'ROLE_CHECK_IN_OR_CHECK_OUT_EVENT',
  'ROLE_VIEW_MINI_GAME_PRIZE',
  'ROLE_VIEW_GUEST',
  'ROLE_RECEIVE_MINI_GAME_PRIZE'
]

export const ZODIAC_SIGN = [
  'ARIES',
  'TAURUS',
  'GEMINI',
  'CANCER',
  'LEO',
  'VIRGO',
  'LIBRA',
  'SCORPIUS',
  'SAGITTARIUS',
  'AQUARIUS',
  'PISCES',
  'CAPRICORN',
  'ZODIAC',
  'UNDEFINED'
]

export const LOCATION = {
  HO_CHI_MINH: 'Hồ Chí Minh',
  HAI_PHONG: 'Hải Phòng',
  HA_NOI: 'Hà Nội',
  DA_NANG: 'Đà Nẵng',
  QUY_NHON: 'Quy Nhơn'
}

export const TEAM_MAP_DEPARTMENT = {
  EARTH: [
    'Air',
    'CS Retail & Nike',
    'CX-CI',
    'PMO',
    'Procurement',
    'Product',
    'Regional & ROM',
    'W&D'
  ],
  WATER: [
    'ADM & IT',
    'CS Retail & Lifestyle',
    'FIN & Legal',
    'HR',
    'HSSE',
    'Omnichannel-Fulfilment',
    'PA',
    'Sales',
    'Senator',
    'Twill'
  ],
  FIRE: [
    'CS Retail & Furniture',
    'CS Retail & Puma',
    'CX-IM',
    'Equipment',
    'Sealand',
    'VCM SCM OPS CTW'
  ],
  AIR: ['AMD', 'CS Sporting', 'CX-4PL', 'CX-EXE', 'CX-OCE'],
  LIGHT: [
    'CS Retail & Fashion',
    'CS Retail & Technology',
    'CS Retail Supermarket',
    'CX-General',
    'SCM OPS'
  ]
}

export const MY_MAERSKTINY = {
  TNP019: '001',
  NBH049: '002',
  NNT014: '003',
  TKN054: '004',
  QHL006: '005',
  THK012: '006',
  QNN004: '007',
  HNN014: '008',
  LTN016: '009',
  TBP019: '010',
  DLE126: '011',
  THP041: '012',
  THN078: '013',
  VHN008: '014',
  DKB026: '015',
  NLT017: '016'
}

export const TEAM_PERFORMANCE_CONTENT = {
  EARTH: `"In the Greek mythology, Gaia, commonly known as the Mother Earth, is one of the first born gods, the god who directly gave birth to the sky, mountains and seas, and also a symbol of nourishment and protection.
  Since ancient times, people have given the Earth a supreme position in their consciousness, perhaps because the ancients felt the preciousness and uncoditional love from this seemingly simple element.
  After so many changes in civilization and lifestyle, the characteristics of Earth have always been the core values ​​that people learn from, which is simplicity, humility, endless love and caring. The Earth performance is inspired by those valuable characteristics and expressed from Maersk family point of view."`,
  WATER: `Representing harmony cooperation, Water team combines various departments to deliver the performance that convey One Maersk spirit in joyful and dedication.With challenges symbolized by the image of Pirates, together, we are united and overcome any challenges occurred within our business to improve life for all by integrating the world.`,
  FIRE: `Fire is not simply an element, it is the fiery spirit inside a person that is always lit up no matter what challenges they face in life, or even lit up brighter to be passed from generation from generation. The Fire Team dance performance is based on the young generation which has just graduated, carrying that fiery spirit on their career path and faced with challenges that weaken, or even extinguish their fire before joinning Maersk, where their spirit is valued, grown and shine brighter than ever!`,
  AIR: `A famous singer,met with a sudden crash that injured her leg and sabotaging her rising career. Since then, her life is plagued with riddicule and mockery, plunging her into the pit of depression. But, within her desperation, she has found her own light - a boy that endlessly went through all the hardships in his life, and even inspire others greatly with his positive mindset. Let's follow the Air performance to know how the girl's life was brightened and lifted up from the seemingly bottomless pit of depression.`,
  LIGHT: `"Have you ever wondered: "" Is Light just for shining?""
  People says that there's only one sun. But do you know that, we all have a shining sun inside our soul, that not only help us stand out, but also to spread the light of love, hopefulness and happiness to the less fortunate life around us. Together, with just small but meaningful action, may our light fade away their darkness, help them feel the light of joy and happiness, especially when Lunar New Year is just around the corner."`
}

export const TEAM_PERFORMANCE_DEPARTMENT = {
  EARTH: [
    'Air',
    'CS Retail & Nike',
    'CX-CI',
    'PMO',
    'Procurement',
    'Product',
    'Regional & ROM',
    'W&D'
  ],
  WATER: [
    'ADM & IT',
    'CS Retail & Lifestyle',
    'FIN & Legal',
    'HR',
    'HSSE',
    'Omnichannel-Fulfilment',
    'PA',
    'Sales',
    'Senator',
    'Twill'
  ],
  FIRE: [
    'CS Retail & Furniture',
    'CS Retail & Puma',
    'CX-IM',
    'Equipment',
    'Sealand',
    'VCM SCM OPS CTW'
  ],
  AIR: ['AMD', 'CS Sporting', 'CX-4PL', 'CX-EXE', 'CX-OCE'],
  LIGHT: [
    'CS Retail & Fashion',
    'CS Retail & Technology',
    'CS Retail Supermarket',
    'CX-General',
    'SCM OPS'
  ]
}

export const PUBLIC_PATH = ['/', '/login', '/404']

//export const SERVER_GRAPHQL = 'https://eventapp.tech/graphql'
export const SERVER_GRAPHQL = 'https://eventapp.space/graphql'
//export const SERVER_GRAPHQL = 'http://localhost:10000/graphql'


//http://103.116.8.51:8010/altair
