import React, { useState } from 'react'
import { useHistory } from 'react-router'
import * as S from './location.style'
import useScrollToTop from '../../hooks/useScrollToTop'
import { useQuery } from '@apollo/client'
import { QUERY } from '../../services'

function Location() {
  const history = useHistory()
  const [imgLocation] = useState('lc')

  const { data: dataMe, refetch } = useQuery(QUERY.me)

  const { id, fullName, eventCheckedIn, rank, tableName } = dataMe?.me || {}

  useScrollToTop()
  return (
    <S.LocationMain>
      <S.LocationContainer>
        <S.LocationHeader
          onClick={() => {
            history.goBack()
          }}
        >
          <S.LocationHeaderIconMenu
            width={23}
            height={23}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.1166 11.5H5.08331"
              stroke="#120D26"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.5 17.9167L5.08331 11.5L11.5 5.08337"
              stroke="#120D26"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </S.LocationHeaderIconMenu>
          <span>Location</span>
        </S.LocationHeader>

        <S.LocationImage src={`/${imgLocation}.png`} />
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
        >
          <div style={{ width: '65%' }}>
            <span style={{ fontSize: '18px' }}>Khách mời</span>
            <span
              style={{ display: 'block', fontSize: '22px', fontWeight: 'bold' }}
            >
              {fullName || ''}
            </span>
          </div>
          <div style={{ width: '35%' }}>
            <span style={{ fontSize: '18px' }}>{rank?.rankName || ''}</span>
            <span
              style={{ display: 'block', fontSize: '22px', fontWeight: 'bold' }}
            >
               {/* BÀN {tableName || ''} */}
               {
                  tableName
                  ? tableName.includes('-')
                    ? (() => {
                        const [table, chair] = tableName.split('-'); // Split the value into table and chair parts
                        return `Table ${table} seat ${chair}`;
                      })()
                    : `Table ${tableName}` // No '-' in tableName, return table number only
                  : 'Not found'
                }
            </span>
          </div>
        </div>
      </S.LocationContainer>
    </S.LocationMain>
  )
}

export default React.memo(Location)
