import React from 'react'
import { Route, Redirect } from 'react-router'
import { isEmpty } from '../../helpers/isEmpty'
import { useAuthToken } from '../../hooks/useAuthToken'

function PrivateRoute(props) {
  const { component: Component, roles, ...rest } = props
  const [token] = useAuthToken()
  if (token && !isEmpty(token)) {
    return (
      <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
    )
  }

  return (
    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
  )
}

export default PrivateRoute
