import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import NotFound from '../404';
import Login from '../Login';
import LoginAdmin from '../LoginAdmin';
import LoginStaff from '../LoginStaff';
import CustomSider from './CustomSider';
import GlobalStyles from './global-styles';

function App() {

  const renderRoutes = () => {
    return (
      <Switch>
        <Route
          key={`public-route-login-page`}
          exact
          path={['/login']}
          render={() => <Login />}
        />

        <Route
          key={`public-route-login-page`}
          exact
          path={['/login-staff']}
          render={() => <LoginStaff />}
        />

        <Route
          key={`public-route-login-page`}
          exact
          path={['/login-admin']}
          render={() => <LoginAdmin />}
        />

        <PrivateRoute
          key={`private2-route-auth-page`}
          path={['']}
          component={CustomSider}
        />

        <Route path="*" component={NotFound} />
      </Switch>
    );
  };

  return (
    <Fragment>
      <GlobalStyles />
      {renderRoutes()}
    </Fragment>
  );
}

export default React.memo(App);
