import { createBrowserHistory } from 'history'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import './style.css'

const SideDrawer = (props) => {
  const history = useHistory()

  const fullName = JSON.parse(localStorage.getItem('USER_BASIC_INFO'))?.fullName

  const roles = JSON.parse(localStorage.getItem('ROLES')) || []

  if (!roles) {
    createBrowserHistory().push('/login')
    window.location.reload()
  }

  let drawerClasses = ['side-drawer']

  if (props.show) {
    drawerClasses = ['side-drawer', 'open']
  }

  const { onHideMenu } = props

  const [menuSelected, setMenuSelected] = useState('dashboard')

  const handleClickMenu = (key) => {
    history.push(`/${key}`)
    setMenuSelected(key)
  }

  const handleLogout = () => {
    localStorage.removeItem('USER_BASIC_INFO')
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.removeItem('ROLES')
    localStorage.removeItem('REFRESH_TOKEN')
    createBrowserHistory().push('/login')
    window.location.reload()
  }

  const handleLogoutStaff = () => {
    localStorage.removeItem('USER_BASIC_INFO')
    localStorage.removeItem('AUTH_TOKEN')
    localStorage.removeItem('ROLES')
    localStorage.removeItem('REFRESH_TOKEN')
    createBrowserHistory().push('/login-staff')
    window.location.reload()
  }

  // onClick={() => onHideMenu(false)}
  // onClick={() => handleLogout()}

  return (
    <nav className={drawerClasses.join(' ')}>
      {roles.includes('ROLE_CHECK_IN_OR_CHECK_OUT_EVENT') && (
        <ul>
          <p className="side-drawer-name">{fullName || ''}</p>

          <li
            className={`${menuSelected === 'staff' ? 'staff' : ''}`}
            onClick={() => handleClickMenu('staff')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                 d="M18.2084 20.125L11.5 15.3333L4.79169 20.125V4.79167C4.79169 4.28334 4.99362 3.79582 5.35307 3.43638C5.71251 3.07693 6.20002 2.875 6.70835 2.875H16.2917C16.8 2.875 17.2875 3.07693 17.647 3.43638C18.0064 3.79582 18.2084 4.28334 18.2084 4.79167V20.125Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Checkin</span>
          </li>

          <li
            className={`${menuSelected === 'staff-mini-1' ? 'staff-mini-1' : ''}`}
            onClick={() => handleClickMenu('staff-mini-1')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                 d="M10 21v-9m3-4H7.5a2.5 2.5 0 1 1 0-5c1.5 0 2.875 1.25 3.875 2.5M14 21v-9m-9 0h14v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-8ZM4 8h16a1 1 0 0 1 1 1v3H3V9a1 1 0 0 1 1-1Zm12.155-5c-3 0-5.5 5-5.5 5h5.5a2.5 2.5 0 0 0 0-5Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>MiniGame 1</span>
          </li>

          <li
            className={`${menuSelected === 'staff-mini-2' ? 'staff-mini-2' : ''}`}
            onClick={() => handleClickMenu('staff-mini-2')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M20 7h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C10.4 2.842 8.949 2 7.5 2A3.5 3.5 0 0 0 4 5.5c.003.52.123 1.033.351 1.5H4a2 2 0 0 0-2 2v2a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V9a2 2 0 0 0-2-2Zm-9.942 0H7.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM13 14h-2v8h2v-8Zm-4 0H4v6a2 2 0 0 0 2 2h3v-8Zm6 0v8h3a2 2 0 0 0 2-2v-6h-5Z"                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>MiniGame 2</span>
          </li>

          <li onClick={() => handleLogoutStaff()}>
            <svg
              width={23}
              height={24}
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M8.625 8.04333V6.24646C8.625 5.7699 8.81431 5.31286 9.15129 4.97588C9.48827 4.6389 9.94531 4.44958 10.4219 4.44958H17.6094C18.0859 4.44958 18.543 4.6389 18.88 4.97588C19.2169 5.31286 19.4062 5.7699 19.4062 6.24646V17.0277C19.4062 17.5043 19.2169 17.9613 18.88 18.2983C18.543 18.6353 18.0859 18.8246 17.6094 18.8246H10.7812C9.78893 18.8246 8.625 18.02 8.625 17.0277V15.2308"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.9375 15.2308L16.5312 11.6371L12.9375 8.04333M3.59375 11.6371H15.8125"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Logout</span>
          </li>
        </ul>
      )}

      {roles.includes('ROLE_VIEW_ANNOUNCEMENT') && (
        <ul>
          {/* <h6>Khách Mời Báo Chí</h6> */}

          <p className="side-drawer-name">{fullName || ''}</p>

          <li
            className={`${menuSelected === 'agenda' ? 'active' : ''}`}
            onClick={() => {
              handleClickMenu('agenda')
              onHideMenu(false)
            }}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M18.2083 3.83337H4.79167C3.73312 3.83337 2.875 4.69149 2.875 5.75004V19.1667C2.875 20.2253 3.73312 21.0834 4.79167 21.0834H18.2083C19.2669 21.0834 20.125 20.2253 20.125 19.1667V5.75004C20.125 4.69149 19.2669 3.83337 18.2083 3.83337Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.3333 1.91663V5.74996"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.66669 1.91663V5.74996"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.875 9.58337H20.125"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Agenda</span>
          </li>
          {/* <li
            className={`${menuSelected === 'couple' ? 'couple' : ''}`}
            onClick={() => handleClickMenu('couple')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2M14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1M2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"s
              />
            </svg>

            <span>Image Voting</span>
          </li> */}
          <li
            className={`${menuSelected === 'card-invite' ? '' : ''}`}
            onClick={() => handleClickMenu('card-invite')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M18.2084 20.125L11.5 15.3333L4.79169 20.125V4.79167C4.79169 4.28334 4.99362 3.79582 5.35307 3.43638C5.71251 3.07693 6.20002 2.875 6.70835 2.875H16.2917C16.8 2.875 17.2875 3.07693 17.647 3.43638C18.0064 3.79582 18.2084 4.28334 18.2084 4.79167V20.125Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Checkin</span>
          </li>

          <li
            className={`${menuSelected === 'announcement' ? '' : ''}`}
            onClick={() => handleClickMenu('announcement')}
          >
            <svg
              width={31}
              height={26}
              viewBox="0 0 31 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M5.07518 4.26147H25.3758C26.7715 4.26147 27.9134 5.22029 27.9134 6.39219V19.1765C27.9134 20.3483 26.7715 21.3072 25.3758 21.3072H5.07518C3.67951 21.3072 2.5376 20.3483 2.5376 19.1765V6.39219C2.5376 5.22029 3.67951 4.26147 5.07518 4.26147Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M27.9134 6.39209L15.2255 13.8496L2.5376 6.39209"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span>Announcement</span>
          </li>

          
          {/* <li
            className={`${menuSelected === 'checkin' ? 'active' : ''}`}
            onClick={() => handleClickMenu('lucky-draw')}
          >
            <svg
              width={23}
              height={23}
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M19.1666 20.125V18.2083C19.1666 17.1917 18.7628 16.2166 18.0439 15.4978C17.325 14.7789 16.35 14.375 15.3333 14.375H7.66665C6.64998 14.375 5.67496 14.7789 4.95607 15.4978C4.23718 16.2166 3.83331 17.1917 3.83331 18.2083V20.125"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.5 10.5417C13.6171 10.5417 15.3334 8.82543 15.3334 6.70833C15.3334 4.59124 13.6171 2.875 11.5 2.875C9.38293 2.875 7.66669 4.59124 7.66669 6.70833C7.66669 8.82543 9.38293 10.5417 11.5 10.5417Z"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Lucky Draw</span>
          </li> */}

          <li onClick={() => handleLogout()}>
            <svg
              width={23}
              height={24}
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="side-drawer-icon"
            >
              <path
                d="M8.625 8.04333V6.24646C8.625 5.7699 8.81431 5.31286 9.15129 4.97588C9.48827 4.6389 9.94531 4.44958 10.4219 4.44958H17.6094C18.0859 4.44958 18.543 4.6389 18.88 4.97588C19.2169 5.31286 19.4062 5.7699 19.4062 6.24646V17.0277C19.4062 17.5043 19.2169 17.9613 18.88 18.2983C18.543 18.6353 18.0859 18.8246 17.6094 18.8246H10.7812C9.78893 18.8246 8.625 18.02 8.625 17.0277V15.2308"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.9375 15.2308L16.5312 11.6371L12.9375 8.04333M3.59375 11.6371H15.8125"
                stroke="#767676"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Logout</span>
          </li>
        </ul>
      )}
    </nav>
  )
}
export default SideDrawer
