import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  border-radius: 2px;
  background-color: #1AA0FF;
  border: 0;
  color: #fff;
  padding: 1px 6px;
  transition: 0.2s background ease;
  &:hover {
    background: #1AA0FF;
  }

  ${({ light }) => {
    if (light) {
      return `
      background-color: #fff;
      border: 1px solid rgba(0,0,0,.9);
      color: #555;
      box-shadow: 0 1px 1px 0 rgb(0 0 0 / 3%);
      &:hover {
        background: rgba(0,0,0,.02)
      }
      `
    }
  }}
`

export const StyledRegister = styled.div`
  min-width: max-content;
  /* background-color: #dddddd; */
  background: #f9f3f1;
  background-position: center center;
  background-size: cover;

  height: 100vh;
  display: flex;
  position: relative;
`

export const StyledRegisterOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  width: 23vw;
  height: 80vh;
  z-index: 1;
  padding: 4rem;
  border-radius: 2rem;
  margin: auto;
  width: 23vw;
  height: 80vh;
  opacity: 0.5;
  @media (max-width: 768px) {
    background-color: #000000;
    width: 100vw;
    height: 100vh;
  }
  z-index: -1;
`

export const Container = styled.div`
  background: url('/bg_login.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  border: 0;
  box-shadow: #d7d7d7 0px 4px 16px 0px;
  border: 1px solid #eeeeee;
  padding: 4rem;
  border-radius: 2rem;
  margin: auto;
  width: 23vw;
  height: 80vh;
  @media (max-width: 768px) {
    background: url('/bg_login.png');
    width: 100vw;
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0;
    border: 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .input-username {
    color: #000;
    font-weight: 500;
    &::placeholder {
      color: #000;
      font-weight: 500;
    }
  }
`

export const HeadingRegister = styled.div`
  text-align: center;
  font-weight: bold;
  color: transparent;
  font-size: 4.5rem;
`

export const FormWrapper = styled.div``

export const Form = styled.form``

export const FormControl = styled.div`
  margin-bottom: 2.5rem;
`

export const FormButton = styled.div`
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  position: relative;
`

export const FormButtonSubmit = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  padding: 0 25px;
  height: 60px;
  color: white;
  text-align: center;
  cursor: pointer;
  border: 0;
  border-radius: 30px;
  background: #1AA0FF;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Quicksand';
  box-shadow: 0px 10px 35px rgba(111, 126, 201, 0.25);
  outline: none;
  &:hover {
    background: #1AA0D5;
  }
`

export const FormButtonSubmitIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translate(-50%, -50%);
`

export const FormFooter = styled.div`
  text-align: center;
  span {
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
  font-size: 14px;

  .link {
    color: #4c6ef4;
  }
`

export const Contact = styled.div`
  color: #fff;
  text-align: center;
  margin-top: auto;
  padding-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.5px;
  a {
    color: #e30613;
  }
`
