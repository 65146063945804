import { useMutation } from '@apollo/client';
import { message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/ErrorMessage';
import InputPassword from '../../components/InputPassword';
import InputText from '../../components/InputText';
import { useLogout } from '../../hooks/useLogout';
import { useUserBasicInfo } from '../../hooks/useUserBasicInfo';
import { MUTATION } from '../../services/index';
import * as S from './login.style';
import { LoadingOutlined } from '@ant-design/icons';
import InputTextLoginBus from '../../components/InputTextLoginBus';

function LoginAdmin(props) {
  const { refreshToken } = props;
  const { history } = props;
  const [, setUserBasicInfo, removeUserBasicInfo] = useUserBasicInfo();
  const logout = useLogout();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const [
    loginAdmin,
    { loading: loginAdmining, data: loginAdminData, error: loginAdminError },
  ] = useMutation(MUTATION.loginEventOrganzier, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    removeUserBasicInfo();
    logout();
    if (refreshToken && typeof refreshToken === 'function') {
      refreshToken(null);
    }
  }, []);

  useEffect(() => {
    if (!loginAdmining && loginAdminData) {
      const { token, roles, id, user } =
        loginAdminData.loginAsEventOrganizer || {};
      message.success('Đăng nhập thành công');
      localStorage.setItem('AUTH_TOKEN', token);
      localStorage.setItem('ROLES', JSON.stringify(roles));
      history.push('/admin/guest');
    }
  }, [loginAdmining, loginAdminData, loginAdminError]);

  useEffect(() => {
    if (loginAdminError) {
      message.error(loginAdminError.message.split(':')[1]);
      localStorage.removeItem('AUTH_TOKEN');
      localStorage.removeItem('REFRESH_TOKEN');
    }
  }, [loginAdminError]);

  const isLogining = loginAdmining;

  const handleLogin = (values) => {
    const { username, password } = values;
    loginAdmin({ variables: { username, password } });
  };

  return (
    <div>
      <S.StyledRegister>
        <S.Container className="container">
          <img alt="" src="/logo3.png" style={{ margin: '50px auto 80px' }} />

          <h6
            style={{
              fontSize: '15px',
              fontWeight: 600,
              marginBottom: '30px',
              color: '#1D1D1B',
            }}
          >
            Log in to manage
          </h6>

          <S.FormWrapper>
            <S.Form onSubmit={handleSubmit(handleLogin)} noValidate>
              <S.FormControl>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Tên tài khoản không được để trống 2',
                    },
                  }}
                  render={({ field }) => (
                    <InputTextLoginBus
                      type="username"
                      name="username"
                      placeholder="Tên tài khoản"
                      onChange={field.onChange}
                      value={getValues('username')}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="username"
                  style={{ paddingLeft: '0rem' }}
                />
              </S.FormControl>
              <S.FormControl>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Mật khẩu không được để trống',
                    },
                  }}
                  render={({ field }) => (
                    <InputPassword
                      name="password"
                      placeholder="Mật khẩu"
                      onChange={field.onChange}
                      value={getValues('password')}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  style={{ paddingLeft: '0rem' }}
                />
              </S.FormControl>
              <S.FormButton>
                <S.FormButtonSubmit type="submit">
                  {isLogining ? <LoadingOutlined /> : 'Đăng nhập'}
                </S.FormButtonSubmit>
              </S.FormButton>
            </S.Form>
          </S.FormWrapper>
        </S.Container>
      </S.StyledRegister>
    </div>
  );
}

LoginAdmin.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,

  refreshToken: PropTypes.func.isRequired,
};

export default React.memo(withRouter(LoginAdmin));
