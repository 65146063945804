import { LoadingOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, withRouter } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";
import InputText from "../../components/InputTextWithIcon";
import { useLogout } from "../../hooks/useLogout";
import { useUserBasicInfo } from "../../hooks/useUserBasicInfo";
import { MUTATION, QUERY } from "../../services/index";
import { ALL_GUESTS_DATA } from "../App/constants";
import * as S from "./login.style";
import "./styles.css";

function Login(props) {
  const { refreshToken } = props;
  const { history } = props;
  const [, setUserBasicInfo, removeUserBasicInfo] = useUserBasicInfo();
  const logout = useLogout();

  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      loginCode: localStorage.getItem("REMEMBER_ME") || "",
      rememberMe: localStorage.getItem("REMEMBER_ME") ? true : false,
    },
  });

  const [login, { loading: logining, data: loginData, error: loginError }] =
    useMutation(MUTATION.loginGuest, {
      errorPolicy: "all",
    });
    
  useEffect(() => {
    removeUserBasicInfo();
    logout();
    if (refreshToken && typeof refreshToken === "function") {
      refreshToken(null);
    }
  }, []);

  useEffect(() => {
    if (!logining && loginData) {
      const { token, roles, id, user } = loginData.loginAsGuest || {};
      // setUserBasicInfo({ roles, id, user });
      message.success("Đăng nhập thành công");
      localStorage.setItem("AUTH_TOKEN", token);
      localStorage.setItem("ROLES", JSON.stringify(roles));
      localStorage.setItem("TEAM", user?.department);
      localStorage.setItem("ID",user?.id)
      history.push("/");
    }

    if (loginError) {
    }
  }, [logining, loginData, loginError]);

  useEffect(() => {
    if (!logining && loginError) {
      localStorage.removeItem("AUTH_TOKEN");
      if (loginError.message.split(":")[1]?.includes("Login code is invalid")) {
        message.error("Mã đăng nhập không hợp lệ!");
      } else {
        message.error(loginError.message.split(":")[1]);
      }

      if (loginError.message.indexOf("Failed to fetch") !== -1) {
        if (!logining) {
          // window.location.reload(true);
        }
      }
    } else if (!logining && loginData) {
      const refreshTokenData = loginData.loginAsGuest.refreshToken;
      localStorage.setItem("REFRESH_TOKEN", refreshTokenData);
    }
  }, [logining, loginError]);

  const isLogining = logining;

  const handleLogin = async (values) => {
    const { loginCode, rememberMe } = values;

    if (rememberMe) {
      localStorage.setItem("REMEMBER_ME", loginCode);
    } else {
      localStorage.removeItem("REMEMBER_ME");
    }

    try {
      const result = await login({ variables: { loginCode } });
      const { fullName, miniGameCode } = result?.data?.loginAsGuest?.user;
      localStorage.setItem("MINI_GAME_CODE", miniGameCode);
      localStorage.setItem("FULL_NAME", fullName);
    } catch (error) {
      // console.log(error)
    }
  };

  return (
    <div>
      <S.StyledRegister>
        <S.Container className="container">
          <img
            alt=""
            src="/logo.png"
            style={{ margin: "20px auto 50px",marginBottom: "90px", maxWidth: "70%", opacity: 100 }}
          />

          <h6
            style={{
              fontSize: "15px",
              fontWeight: 500,
              textAlign : "left",
              marginBottom: "30px",
              color: "#FFFFFF",
              marginRight: "auto",
            }}
          >
            LOGIN
          </h6>
          <S.FormWrapper>
            <S.Form onSubmit={handleSubmit(handleLogin)} noValidate>
              <S.FormControl>
                <Controller
                  name="loginCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Tên tài khoản không được để trống!",
                    },
                  }}
                  render={({ field }) => (
                    <InputText
                      className="input-username"
                      type="loginCode"
                      name="loginCode"
                      placeholder="EMPLOYEE ID"
                      onChange={field.onChange}
                      value={getValues("loginCode")}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="loginCode"
                  style={{ paddingLeft: "0rem" }}
                />
              </S.FormControl>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <input
                  type="checkbox"
                  id="switch"
                  class="switch-input"
                  {...register("rememberMe")}
                />
                <label for="switch" class="switch"></label>
                <span style={{ color: "#fff", marginLeft: "10px" }}>
                  Remember me?
                </span>
              </div>

              <S.FormButton>
                <S.FormButtonSubmit type="submit">
                  {isLogining ? <LoadingOutlined /> : "LOGIN"}
                </S.FormButtonSubmit>
                <S.FormButtonSubmitIcon>
                  <svg
                    width={13}
                    height={13}
                    viewBox="0 0 13 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 6.5C0 6.05127 0.363769 5.6875 0.8125 5.6875H9.87179L5.91095 1.72666C5.58722 1.40293 5.59186 0.876646 5.92125 0.558678C6.24252 0.248547 6.75305 0.253051 7.0688 0.568802L12.9356 6.43565C12.9712 6.47119 12.9712 6.52881 12.9356 6.56435L7.07001 12.43C6.75521 12.7448 6.2448 12.7448 5.93001 12.43C5.61623 12.1162 5.61509 11.6078 5.92746 11.2926L9.87179 7.3125H0.8125C0.363769 7.3125 0 6.94873 0 6.5Z"
                      fill="white"
                    />
                  </svg>
                </S.FormButtonSubmitIcon>
              </S.FormButton>
            </S.Form>
          </S.FormWrapper>
          <S.Contact>
            Không thể đăng nhập?{" "}
            <Link
              to={{ pathname: "https://www.facebook.com/giangsonviet.page" }}
              target="_blank"
            >
              Liên hệ
            </Link>
          </S.Contact>
        </S.Container>
      </S.StyledRegister>
    </div>
  );
}

Login.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  refreshToken: PropTypes.func.isRequired,
};

export default React.memo(withRouter(Login));
