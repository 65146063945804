import styled from 'styled-components';

export const FormControl = styled.div`
  margin-bottom: 0px;
  width: 100%;
  input {
    display: block;
    width: 100%;
    padding: 6px 8px;
    background: #ffffff;
    border: 1px solid #d3d8de;
    border-radius: 4px;
    outline: none;
    color: #363a40;
    font-size: 16px;
    &:focus {
      border: 1px solid #00aefd;
    }
  }
`;
