import { AUTH_TOKEN } from '../containers/App/constants.js';
import { isTokenExpired } from '../helpers/jwtHelper';

export const useAuthToken = () => {
	const token = localStorage.getItem(AUTH_TOKEN);

	const setAuthToken = (token) => localStorage.setItem(AUTH_TOKEN, token);

	const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN);

	const checkToken = () => {
		if (token !== null && token !== undefined) {
			const isExpired = isTokenExpired(token);
			if (!isExpired) {
				setAuthToken(token);
			} else {
				localStorage.removeItem(AUTH_TOKEN);
				setAuthToken(null);
			}
		}
	};

	const refreshToken = (data = {}, callback) => {
		const token = data[AUTH_TOKEN];
		if (token) {
			setAuthToken(token);
		} else {
			removeAuthToken();
		}
		callback(token);
	};

	return [token, setAuthToken, removeAuthToken, checkToken, refreshToken];
};
