import { Result } from 'antd';
import React from 'react';

function NotFound(props) {
	return (
		<Result
			status="404"
			title="Page not found"
			subTitle="Sorry, the page you visited does not exist."
		/>
	);
}

export default React.memo(NotFound);
